<template>
  <Dyakuyu tenant-alias="platizhnyytsent">
    <b-card-header header-bg-variant="white" header-border-variant="0">
      <b-img
        center
        src="/images/platizhnyy-tsentr_logo_dark.svg"
        style="width: 50%"
      />
    </b-card-header>

    <b-card-title class="text-center">
      ТОВ “Фінансова компанія “Платіжний Центр”
    </b-card-title>
  </Dyakuyu>
</template>

<script>
import Dyakuyu from "@/containers/Dyakuyu.vue";
export default {
  name: "DyakuyuPlatizhnyyTsentr",
  components: {
    Dyakuyu,
  },
};
</script>
